import MessageProcessor from "../../Message/MessageProcessor";

export default class ClientExampleProcessor {
    supports({ name }) {
        return name === 'app:worker:registered'
    }

    handle(message, { messageProcessor }) {
        console.log('Event: ', message);


        const job = messageProcessor.createMessage({
            name: 'worker:example',
            params: 42,
            scope: MessageProcessor.SCOPE_WORKER
        });

        console.log('JOB created!', job);

        job.then((response) => {
            console.log('ANSWER IS', response);
        }).send();
    }
}
