export const toWorkerUrl = (params) => {
    let url = params.workerUrl;

    url.includes('?') ? url = `${url}&` : url = `${url}?`;

    const getValue = (value) => {
        if (value === null) {
            return 'null';
        }

        return value.toString();
    }

    for (let key in params) {
        if (params.hasOwnProperty(key)) {
            const value = params[key];

            if (Array.isArray(value)) {
                value.forEach((item) => {
                    url = `${url}&${key}=${getValue(item)}`;
                });
            } else {
                url = `${url}&${key}=${getValue(value)}`;
            }
        }
    }

    return url;
}
