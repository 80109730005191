import StampMessage from "./StampMessage";

export default class MessageProcessor {
    #context;
    #channel;
    currentScope;
    #processors = [];

    constructor({ context, currentScope, processors }) {
        this.#context = context;
        this.#channel = new MessageChannel();
        this.currentScope = currentScope;
        this.#processors = processors;
    }

    handle(messageEvent) {
        const stampMessage = this.createMessage(messageEvent);

        this.#processors
            .filter((processor) => {
                return processor.supports(stampMessage);
            })
            .forEach((processor) => {
                return processor.handle(stampMessage, {
                    messageProcessor: this,
                    context: this.#context,
                    channel: this.#channel,
                    currentScope: this.currentScope,
                });
            })
    }

    dispatch(message, ports = []) {
        //this.#context.postMessage(message, ports);

        const dispatchToSelf = () => {
            this.handle(message);
        }

        const dispatchToWorld = () => {
            this.#context.postMessage(message, ports);
        }

        switch (true) {
            case message?.scope === MessageProcessor.SCOPE_APP:
                dispatchToSelf();
                dispatchToWorld();
                break;

            case message?.scope === this.currentScope:
                dispatchToSelf();
                break;

            case message?.scope !== this.currentScope:
                dispatchToWorld();
                break;
        }
    }

    createMessage(data) {
        return StampMessage.create(data, {messageProcessor: this});
    }

    static get SCOPE_APP() {
        return 'app';
    }

    static get SCOPE_CLIENT() {
        return 'client';
    }

    static get SCOPE_WORKER() {
        return 'worker';
    }
}
