import StampMessage from "../Message/StampMessage";

export const subscribe = (messageName, callback) => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('message', (messageEvent) => {
            const { name } = messageEvent.data;

            if (name === messageName) {
                const stampMessage = StampMessage.create(messageEvent, {});
                callback(stampMessage);
            }
        })
    }
}
