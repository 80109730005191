import ParameterBag from "../DTO/ParameterBag";
import MessageProcessor from "../Message/MessageProcessor";
import {toWorkerUrl} from "../Serializers/ParameterBagSerializer";
import {clientProcessors} from "../Processors/ClientProcessors";

export default class RedirectClient {
    params;
    registration;
    messageProcessor;

    constructor(params) {
        this.params = new ParameterBag(params);
    }

    init() {
        if (this.params.workerRegister && 'serviceWorker' in navigator) {
            navigator.serviceWorker.register(toWorkerUrl(this.params))
                .then((registration) => {
                    console.log('Service worker registered successfully!');
                    this.registration = registration;

                    this.messageProcessor = new MessageProcessor({
                        context: this,
                        currentScope: MessageProcessor.SCOPE_CLIENT,
                        processors: clientProcessors,
                    });

                    navigator.serviceWorker.addEventListener('message', (message) => {
                        this.messageProcessor.handle(message);
                    });

                    return registration.update()
                        .then(() => {
                            this.messageProcessor.dispatch({
                                name: 'app:worker:registered',
                                scope: 'app'
                            });
                        })
                })
                .catch((error) => {
                    console.error('Service worker was not registered!', error);
                })
        } else {
            console.warn('Service worker disabled');
        }
    }

    postMessage({ name, params, scope, source = MessageProcessor.SCOPE_CLIENT }, ports = []) {
        this.registration?.active?.postMessage({ name, params, scope, source }, ports);
    }

    executeBackgroundTask(name, params, callback) {
        this.messageProcessor.createMessage({
            name,
            params,
            source: MessageProcessor.SCOPE_CLIENT,
            scope: MessageProcessor.SCOPE_WORKER
        })
            .then((response) => callback(response))
            .send();
    }
}
