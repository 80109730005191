import {createRedirectClient} from "../src";

createRedirectClient({
    project: 'playfortuna'
})
    .then(() => console.log('created'))
    .catch((error) => console.log('whoops!', error));


import {subscribe} from "../src/Cache";

subscribe('fetch:successful', (message) => {
    console.log('FETCH IN MAIN THREAD', message);
})
