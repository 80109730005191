export default class StampMessage {
    params;
    name;
    scope;
    source;
    messageEvent;
    messageProcessor;
    messageChannel;

    constructor(
        { name, params, scope, source },
        { messageProcessor, messageEvent, messageChannel = new MessageChannel() }
    ) {
        this.messageEvent = messageEvent;
        this.messageProcessor = messageProcessor;
        this.messageChannel = messageChannel;

        this.params = params;
        this.name = name;
        this.scope = scope;
        this.source = messageProcessor.currentScope;
    }

    static createFromMessageEvent(
        messageEvent,
        { messageProcessor, messageChannel}
    ) {
        const { name, params, scope, source } = messageEvent.data;
        return new StampMessage({ name, params, scope, source }, { messageProcessor, messageChannel, messageEvent });
    }

    static create(message, { messageProcessor, messageChannel }) {
        return typeof message.data === 'undefined'
            ? new StampMessage(message, {messageProcessor, messageChannel})
            : StampMessage.createFromMessageEvent(message, {messageProcessor, messageChannel});
    }

    respondWith(message) {
        this.messageEvent?.ports?.forEach(port => {
            port.postMessage(message?.serialize(), [message?.messageChannel?.port2]);
        })
    }

    respondWithData({ name, params, scope, source }) {
        const message = new StampMessage(
            { name, params, scope, source },
            { messageProcessor: this.messageProcessor }
        )

        return this.respondWith(message);
    }

    respondWithParams(params) {
        const getScope = () => {
            if (this.scope === 'app') {
                return 'app';
            }
            return this.scope === 'worker' ? 'client' : 'worker';
        }

        const message = new StampMessage(
            { name: this.name, params, scope: getScope(), source: this.source },
            { messageProcessor: this.messageProcessor }
        )

        return this.respondWith(message);
    }

    then(callback) {
        this.messageChannel.port1.onmessage = (messageEvent) => {
            const stampMessage = StampMessage.createFromMessageEvent(
                messageEvent, {messageProcessor: this.messageProcessor}
            );
            callback(stampMessage);
        }
        return this;
    }

    send() {
        this.messageProcessor.dispatch(this.serialize(), [this.messageChannel.port2]);
        return this;
    }

    serialize() {
        return {
            name: this.name,
            params: this.params,
            scope: this.scope,
            source: this.source,
        }
    }
}
