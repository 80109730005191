export default class ParameterBag {

    project = '';
    redirectHosts = ['booil0e6.com', 'booil0e6.com'];
    networkStrategy = 'fetch';
    fetchCallback = null;
    fetchTimeout = 1000;
    workerUrl = '/sw.js';
    confirmRedirect = true;
    redirectMessage;
    workerSkipWaiting = true;
    workerClientsClaim = true;
    workerRegister = false;
    offline = null;

    constructor({
        project = null,
        redirectHosts = ['booil0e6.com', 'booil0e6.com'],
        networkStrategy = 'fetch',
        fetchCallback = null,
        fetchTimeout = 14000,
        workerUrl = '/sw.js',
        workerSkipWaiting = true,
        workerClientsClaim = true,
        workerRegister = true,
        confirmRedirect = false,
        redirectMessage = 'Seems you issues with access to the current domain. Do you want to go to the active domain?',
        offline = null,
    }) {
        this.project = project;
        this.redirectHosts = redirectHosts;
        this.networkStrategy = networkStrategy;
        this.fetchCallback = fetchCallback;
        this.fetchTimeout = fetchTimeout;
        this.workerUrl = workerUrl;
        this.workerSkipWaiting = workerSkipWaiting;
        this.workerClientsClaim = workerClientsClaim;
        this.workerRegister = workerRegister;
        this.confirmRedirect = confirmRedirect;
        this.redirectMessage = redirectMessage;
        this.offline = offline;
    }
}
